<template>
  <div class="home">
    <!-- 顶部导航栏 -->
    <div
      class="top-nav"
      :class="[
        {
          fixedbtn1: this.alllist.isMenu ? 'fixedbtn1' : '',
        },
        {
          fixedbtn:
            this.alllist.fixed && !this.alllist.isMenu ? 'fixedbtn' : '',
        },
      ]"
      id="home"
      ref="topnav"
    >
      <div class="nav">
        <router-link to="/">
          <div class="nav-left">
            <img src="../assets/image/fqkj1.png" />
            <img
              src="../assets/image/fqkj2.png"
              v-show="!this.alllist.isMenu && this.alllist.fixed"
            />
          </div>
        </router-link>
        <!-- 企业开始 -->
        <div class="qiyeicn">
          <a href="https://open.work.weixin.qq.com" target="_blank"
            ><img
              src="https://open.work.weixin.qq.com/service/img?id=ww7b9b75a9cbd7d374&t=isp&c=white&s=small"
              srcset="
                https://open.work.weixin.qq.com/service/img?id=ww7b9b75a9cbd7d374&t=isp&c=white&s=small@2x 2x
              "
              referrerpolicy="unsafe-url"
              alt="企业微信"
          /></a>
          <div class="popup">
            <div class="top">
              <div class="flex">
                <img src="../assets/image/logo.png" alt="" />
                <div class="popupDetail">
                  <h4>番茄科技</h4>
                  <div class="popuptext flex">
                    <span >广东番茄科技有限公司 <span class="icon-wrap"><img src="../assets/image/renzheng.png" alt=""></span></span>
                    <div>
                      <a href="https://open.work.weixin.qq.com" target="_blank">详情 ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="intro">
              番茄科技于2021年成⽴，腾讯一级服务商。专注于为企业提供运营⼯具，涵盖保险、证券、银行、地产等行业，提供积分、营销、礼品等解决方案。
            </div>
          </div>
        </div>
        <!-- 企业结束 -->
        <div class="nav-right">
          <div class="nav-ri-home">
            <span data-id="home" @click="goto">首页</span>
          </div>
          <div class="nav-ri-home" style="position:relative;line-height:44px">
            <span>番茄品牌</span>
            <img
              v-show="alllist.fixed"
              class="navArrows"
              src="../assets/image/downicn.png"
            />
            <img class="navArrows" src="../assets/image/downicn2.png" />
            <div
              class="nav-level-second"
              :style="
                alllist.fixed == true
                  ? 'background-color: #fff;border: 1px solid #eee;'
                  : 'background-color: rgba(39, 38, 38, 0.5);'
              "
            >
              <router-link to="/financial">
                <a
                  :style="alllist.fixed == true ? 'color:#333' : 'color:#fff;'"
                >
                  金融
                </a>
              </router-link>
              <router-link to="/integral">
                <a
                  :style="alllist.fixed == true ? 'color:#333' : 'color:#fff;'"
                >
                  积分
                </a>
              </router-link>
              <router-link to="/marketing">
                <a
                  :style="alllist.fixed == true ? 'color:#333' : 'color:#fff;'"
                >
                  营销
                </a>
              </router-link>
              <router-link to="/welfare">
                <a
                  :style="alllist.fixed == true ? 'color:#333' : 'color:#fff;'"
                >
                  福利
                </a>
              </router-link>
            </div>
          </div>
          <div class="nav-ri-home">
            <span data-id="cpty" @click="goto">产品体验</span>
          </div>
          <div class="nav-ri-home">
            <span data-id="hzhb" @click="goto">合作伙伴</span>
          </div>
          <!-- <div class="nav-ri-home" style="position:relative;line-height:44px">
            <span>新闻动态</span>
            <img
              v-show="alllist.fixed"
              class="navArrows"
              src="../assets/image/downicn.png"
            />
            <img class="navArrows" src="../assets/image/downicn2.png" />
            <div
              class="nav-level-second2"
              :style="
                alllist.fixed == true
                  ? 'background-color: #fff;border: 1px solid #eee;'
                  : 'background-color: rgba(39, 38, 38, 0.5);'
              "
            >
              <router-link to="/news">
                <a
                  :style="alllist.fixed == true ? 'color:#333' : 'color:#fff;'"
                >
                  公司新闻
                </a>
              </router-link>
              <router-link to="/news">
                <a
                  :style="alllist.fixed == true ? 'color:#333' : 'color:#fff;'"
                >
                  行业资讯
                </a>
              </router-link>
            </div>
          </div> -->
          <div class="nav-ri-home">
            <span data-id="lxwm" @click="goto">联系我们</span>
          </div>
        </div>
        <div
          class="nav-right-menu"
          v-show="!this.alllist.isMenu"
          :class="this.alllist.isMenu || this.alllist.fixed ? 'menuactive' : ''"
          @click="togomenu"
          ref="menuText"
        >
          O
        </div>
        <div
          class="nav-right-menu"
          v-show="this.alllist.isMenu"
          :class="this.alllist.isMenu ? '' : 'menuactive'"
          @click="togomenu"
          ref="menuText"
        >
          X
        </div>
      </div>
    </div>

    <!-- 移动隐藏菜单star -->
    <div
      class="top-menu"
      :class="this.alllist.isMenu ? 'menu-show' : ''"
      ref="menu"
    >
      <div class="top-menu-nav">
        <div class="nav-ri-home" data-id="home" @click="gotob">
          首页
        </div>
        <div class="mobileTab-list">
          <van-collapse v-model="activeNames">
            <van-collapse-item name="1">
              <template #title>
                <div class="mobile-item">
                  <div>
                    番茄品牌
                  </div>
                </div>
              </template>
              <div class="tabList">
                <div class="item">
                  <router-link to="/financial">
                    <div class="item-text">
                      金融
                    </div>
                  </router-link>
                  <router-link to="/integral">
                    <div class="item-text">
                      积分
                    </div>
                  </router-link>
                  <router-link to="/marketing">
                    <div class="item-text">
                      营销
                    </div>
                  </router-link>
                  <router-link to="/welfare">
                    <div class="item-text">
                      福利
                    </div>
                  </router-link>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="nav-ri-home" data-id="cpty" @click="gotob">产品体验</div>
        <div class="nav-ri-home" data-id="hzhb" @click="gotob">合作伙伴</div>
        <div class="nav-ri-home" data-id="lxwm" @click="gotob">联系我们</div>
      </div>
    </div>
    <!-- 移动隐藏菜单end -->

    <!-- 背景 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>连接科技 共生未来</div>
            <div>探索面向未来的科技创新</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品体验层开始 -->
    <div class="products-box" id="cpty">
      <div class="products-layout">
        <!-- pc star -->
        <div class="products-pro">
          <div class="pro-top">
            <div class="pro-topnav">
              <div
                :class="[
                  { 'pro-text': true },
                  { active: index === alllist.proindex },
                ]"
                v-for="(item, index) in alllist.prolist"
                :key="index"
                @click="toggle(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="pro-lower">
            <div
              class="lower-left"
              v-for="(item, index) in alllist.prolist"
              :key="index"
              v-show="alllist.proindex == index"
            >
              <div class="left-details">
                <div
                  class="details-name add-bold"
                  :style="{ color: item.color }"
                >
                  {{ item.title }}
                </div>
                <div class="details-title add-bold">{{ item.btitle }}</div>
                <div class="details-det">
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </div>
              <div class="left-vxcode" v-if="item.vxcodeimg">
                <div class="vxcode-border">
                  <div class="vxcode-img">
                    <img class="auto-img" :src="item.vxcodeimg" />
                  </div>
                  <p>使用微信扫描小程序码体验</p>
                </div>
              </div>
            </div>
            <div class="lower-right">
              <div
                class="right-phone"
                v-for="(item, index) in alllist.prolist"
                :key="index"
                v-show="alllist.proindex == index"
              >
                <img
                  :src="item.phoneimg"
                  class="phone-text"
                  alt="phone-text"
                  v-show="alllist.proindex == index"
                />
                <img
                  src="../assets/image/phone.png"
                  class="phone-border"
                  alt="phone-border"
                />
                <div class="right-loading">。。。</div>
              </div>
            </div>
          </div>
        </div>
        <!-- pc end -->
        <!-- 手机star -->
        <div
          class="pro-phone"
          v-for="(item, index) in alllist.prolist"
          :key="index"
          @click="mobileOpen(item)"
        >
          <div class="mobile-pro" v-if="item.mobileSmaller">
            <div class="mobile-container">
              <div class="mobile-detail">
                <div class="mobile-info">
                  <div
                    class="mobile-pro-name add-bold"
                    :style="{ color: item.color }"
                  >
                    {{ item.title }}
                  </div>
                  <div class="mobile-pro-label add-bold">
                    {{ item.btitle }}
                  </div>
                  <div class="mobile-pro-text">
                    <p>
                      {{ item.text }}
                    </p>
                  </div>
                </div>
                <div class="mobile-nav">
                  <div class="mobile-list">
                    <van-collapse v-model="activeNames" v-if="item.vxcodeimg">
                      <van-collapse-item name="1">
                        <template #title>
                          <div class="mobile-item" v-if="item.mobileWx">
                            <div
                              class="item-icna"
                              :style="{ backgroundColor: item.color }"
                            ></div>
                            <div :style="{ color: item.color }">
                              {{ item.mobileWx }}
                            </div>
                            <!-- <div
                              class="item-icnc"
                              :style="{ backgroundColor: item.color }"
                            ></div> -->
                          </div>
                        </template>
                        <img :src="item.vxcodeimg" alt="微信二维码" />
                      </van-collapse-item>
                    </van-collapse>
                  </div>
                  <!-- 小程序 -->
                  <!-- <div class="mobile-item" v-if="item.mobileWx">
                    <div
                      class="item-icna"
                      :style="{ backgroundColor: item.color }"
                    ></div>
                    <div :style="{ color: item.color }">
                      {{ item.mobileWx }}
                    </div>
                    <div
                      class="item-icnc"
                      :style="{ backgroundColor: item.color }"
                    ></div>
                  </div> -->
                  <!-- 网页版 -->
                  <!-- <div class="mobile-item item-wy" v-if="item.mobileWy">
                    <div
                      class="item-icnb"
                      :style="{ backgroundColor: item.color }"
                    ></div>
                    <div :style="{ color: item.color }">
                      {{ item.mobileWy }}
                    </div>
                    <div
                      class="item-icnc"
                      :style="{ backgroundColor: item.color }"
                    ></div>
                  </div> -->
                  <div class="mobile-item" v-if="item.mobileLjty">
                    <div
                      class="item-icna"
                      :style="{ backgroundColor: item.color }"
                    ></div>
                    <div :style="{ color: item.color }">
                      {{ item.mobileLjty }}
                    </div>
                    <div
                      class="item-icnc"
                      :style="{ backgroundColor: item.color }"
                    ></div>
                  </div>
                </div>
                <div class="mobile-phone scroll-slide-up">
                  <img class="mobile-phone-text" :src="item.phoneimg" />
                  <img
                    class="mobile-hone-border"
                    src="../assets/image/phone.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="phone-narrow" v-else>
            <div class="narrow-tab">
              <img
                class="narrow-icn"
                src="https://resource.nocode.com/assets/product/icn-global-clinical-trials@3x.png"
              />
              <div class="narrow-name">{{ item.title }}</div>
              <div style="flex: 1 1 auto;"></div>
              <img
                class="narrow-icnb"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIAQMAAABvIyEEAAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMAqK6e5uIAAAAfSURBVCjPY2BgYGD+f4ABAkZZeFn/YYDOrNGwJ5oFAIDp1Q05VcLkAAAAAElFTkSuQmCC"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 手机end -->
      </div>
    </div>
    <!-- 产品体验层结束 -->

    <!-- 移动轮播开始 -->
    <div class="phone-swiper">
      <img src="../assets/image/home/timg.png" alt="" />
      <!--基础存放容器-->
      <div class="swiper-container">
        <!-- 需要进行轮播的部分 -->
        <div class="swiper-wrapper">
          <!-- 每个节点 -->
          <div
            class="swiper-slide"
            v-for="(v, i) in alllist.swiperlist"
            :key="i"
          >
            <img :src="v.img" />
          </div>
        </div>
      </div>
      <img src="../assets/image/home/timg2.png" alt="" />
    </div>
    <!-- 移动轮播结束 -->

    <!-- 品牌开始 -->
    <div class="brand">
      <h2 class="brand-title">番茄品牌</h2>
      <p class="brand-subtitle">
        针对行业特性、发展趋势及细分场景深度定制，赋能品牌发展
      </p>
      <div class="brand-list">
        <div
          class="brand-item"
          v-for="(v, i) in this.alllist.brandList"
          :key="i"
          @mouseenter="enter(i)"
          :class="{ hover: i == 0 }"
        >
          <div class="item-intro">
            <h3>{{ v.title }}</h3>
            <p>{{ v.t1 }}</p>
            <p>{{ v.t2 }}</p>
            <p>{{ v.t3 }}</p>
          </div>
          <div class="brand-detail">
            <h3>
              <img :src="v.timg" alt="" />
              <span>{{ v.title }}</span>
            </h3>
            <p>
              {{ v.text }}
            </p>
            <ul class="clear">
              <li class="tedian">{{ v.t1 }}</li>
              <li class="tedian">{{ v.t2 }}</li>
              <li class="tedian">{{ v.t3 }}</li>
            </ul>
            <div class="item-bottomDetail">
              <span class="title">合作案例</span>
              <a v-for="(item, index) in v.logo" :key="index">
                <img class="logo" :src="item" alt="" />
              </a>
              <!-- <a class="item-more">
                了解详情 >
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 品牌结束 -->

    <!-- 产品与服务层开始 -->
    <dir class="products-box">
      <div class="products-layout">
        <div class="service-box">
          <div class="title">产品与服务</div>
          <div class="subtitle">融合业务场景，重塑企业核心竞争力</div>
          <div class="serviceList">
            <div
              class="serviceItem"
              v-for="(v, i) in alllist.serviceList"
              :key="i + 1"
              @click="alllist.serviceNum = i"
              :class="{ active: alllist.serviceNum == i }"
            >
              <img :src="alllist.serviceNum == i ? v.icon2 : v.icon" />
              <h3>{{ v.title }}</h3>
            </div>
          </div>
          <div class="serviceDetail">
            <div
              class="itemDetail"
              v-for="(v, i) in alllist.serviceDetail"
              :key="i"
              v-show="alllist.serviceNum == i"
              :style="{
                background: 'url(' + v.bg + ') top right no-repeat',
              }"
            >
              <p>
                {{ v.text }}
              </p>
              <ul>
                <li class="detailCell">{{ v.cell }}</li>
                <li class="detailCell">{{ v.cell2 }}</li>
                <li class="detailCell">{{ v.cell3 }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </dir>
    <!-- 产品与服务层结束 -->

    <!-- 合作伙伴层 -->
    <div class="cooperation-box" id="hzhb">
      <div class="cooperation-layout">
        <div class="cooperation-coop">
          <h2 class="add-bold">合作伙伴</h2>
          <div class="cooperation-text">
            <p>
              创造有活力的品牌，提升用户体验和品牌价值感。以下合作伙伴排名不分先后。
            </p>
          </div>
          <!-- 移动——合作 -->
          <a>
            <div class="mobile-cooper-btn">
              <img
                class="cooper-img"
                src="../assets/image/icn-business-small-blue@3x.png"
              />
              <div>想要与我们合作？点此联系</div>
              <dir class="mobile-cooper-right"></dir>
            </div>
          </a>
          <!-- 移动——合作 -->
          <ul>
            <li
              v-for="(item, index) in alllist.cooperImgList"
              class="scroll-slide-up"
              :key="index"
            >
              <img :src="item.cooperimg" loading="lazy" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script scoped>
import "../assets/less/home.less";
import alllist from "../assets/js/index";
import "../../node_modules/swiper/dist/css/swiper.css";
import Swiper from "../components/swiper";
import bottom from "../components/Bottom";
export default {
  name: "Home",
  data() {
    return {
      // *全部列表
      alllist,
      scrollTop: "",
      activeNames: [],
    };
  },
  components: {
    bottom,
  },
  methods: {
    initSwiper() {
      new Swiper(".swiper-container", {
        //设置轮播的循环方式
        loop: true,

        //设置自动播放间隔时间
        autoplay: 2000,

        // 轮播效果,默认为平滑轮播
        effect: "slide",

        // 用户中断轮播后续播
        autoplayDisableOnInteraction: false,
      });
    },
    // 鼠标移入
    enter(index) {
      let hover = document.getElementsByClassName("brand-item");
      hover.forEach(function(list, i) {
        if (i == index) {
          hover[i].className = "brand-item hover";
        } else {
          hover[i].className = "brand-item";
        }
      });
    },
    //*判断到位置加类名
    fixedActiveBtn() {
      let cptyheight = document.querySelector("#cpty").offsetTop;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      scrollTop >= cptyheight
        ? (this.alllist.fixed = true)
        : (this.alllist.fixed = false);
    },

    //*跳转到对应位置
    goto(e) {
      let id = e.target.dataset.id;
      let scrheight = document.querySelector("#" + id);
      if (id == undefined || scrheight == null) {
        this.$router.push({
          name: "Home",
        });
      } else {
        window.scrollTo({
          top: scrheight.offsetTop,
          behavior: "smooth",
        });
      }
    },

    gotob(e) {
      this.goto(e);
      this.togomenu();
    },

    //*切换激活状态
    toggle(i) {
      if (i == this.alllist.proindex) {
        return;
      }
      this.alllist.proindex = i;
    },

    // *菜单切换
    togomenu() {
      if (this.alllist.isMenu) {
        this.alllist.isMenu = false;
      } else {
        this.alllist.isMenu = true;
      }
      console.log(this.alllist.isMenu);
    },

    // 手机端展开b
    mobileOpen(item) {
      if (item.mobileSmaller) {
        return;
      } else {
        item.mobileSmaller = true;
      }
    },
  },

  mounted() {
    // *滚动
    window.addEventListener("scroll", this.fixedActiveBtn);
    this.initSwiper();
    // 刷新一次
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },

  created() {},
};
</script>

<style lang="less" scoped>
/deep/.mobile-list,
.van-collapse-item {
  width: 100%;
}
/deep/ .swiper-slide-active > img {
  width: 100% !important;
  margin-top: -3rem;
}
.swiper-container {
  width: 100rem;
  height: 125rem;
  margin-top: 40px;
  overflow: unset;
}

.swiper-slide img {
  width: 90%;
  object-fit: cover;
  transition: all 0.5s ease;
}
</style>
